import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {ApiContext} from '../apiContext';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Redirect,
//   useHistory,
//   useLocation
// } from "react-router-dom";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '', password: '', error: '' };
    this.login = this.login.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  componentDidMount() {
    this.loginByCookie();
  }
  componentWillUnmount() {}

  handleInputChange(event) {
    const target = event.target;
    const value = target.value; // target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  login(event) {
    event.preventDefault();
    this.setState({ error: '' });
    const payload = JSON.stringify({
      email: this.state.email,
      password: this.state.password,
    });
    fetch(this.context.API_URL + 'login', {
      method: 'POST',
      body: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          this.setState({
            error: 'Please try again (' + res.statusText + ').',
          });
          this.props.onUserChange({});
          // throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        this.props.onUserChange(data);
      })
      .catch((error) => {
        this.setState({
          error: 'Network Error (' + error + ').',
        });
        this.props.onUserChange({});
      });
  }

  loginByCookie() {
    const token = this.accessCookie('api_token');
    if (token) {
      fetch(this.context.API_URL + 'user?api_token=' + token, {
        headers: {
          Accept: 'application/json',
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data === undefined || 'api_token' in data == false) {
            this.props.onUserChange({});
            return;
          }
          data['api_token']=token; // decrypted
          this.props.onUserChange(data);
        })
        .catch(console.log);
    }
  }

  render() {
    if ('api_token' in this.props.currentUser == true) {
      return <Redirect to="/tool/bildverteilung" />;
    }
    return (
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              <h3>Login</h3>
            </div>
            <div className="card-text">
              <form onSubmit={this.login}>
                <div>
                  <label className="labelLogin">E-Mail: </label>
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div>
                  <label className="labelLogin">Password: </label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div>
                  <button style={{ width: '100px', height: '40px' }}>
                    Login
                  </button>
                </div>
                <div>{this.state.error}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    let allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim();
      if (temp.indexOf(name) == 0) {
        return temp.substring(name.length, temp.length);
      }
    }
  }
}

Login.contextType = ApiContext;
