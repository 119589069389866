import React from 'react';
import { FtpSetting } from './FtpSetting';
import { Redirect } from 'react-router-dom';
import { ApiContext } from '../apiContext';

export class Bildverteilung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ftpSettings: [],
      photographers: [],
      loading: false,
      error: '',
    };
    this.loadAgain = this.loadAgain.bind(this);
    this.logout = this.logout.bind(this);
    this.eventHandler = this.eventHandler.bind(this);
    this.newSetting = this.newSetting.bind(this);
  }

  componentDidMount() {
    this.loadAgain();
    this.loadPhotographers();
  }
  componentWillUnmount() {}

  loadAgain() {
    this.setState({ ftpSettings: [], loading: true, error: '' });
    if ('api_token' in this.props.currentUser == false) {
      return;
    }
    fetch(
      this.context.API_URL +
        'ftpsettings?api_token=' +
        this.props.currentUser.api_token,
      {
        headers: { Accept: 'application/json' },
      }
    )
      .then((res) => {
        if (!res.ok) {
          this.setState({
            ftpSettings: [],
            loading: false,
            error: 'HTTP error! Status: ' + res.statusText,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        this.setState({ ftpSettings: data, loading: false, error: '' });
      })
      .catch((error) => {
        this.setState({
          error: 'Network Error (' + error + ').',
        });
        this.setState({
          ftpSettings: [],
          loading: false,
          error: 'HTTP error! ' + error,
        });
      });
  }

  logout() {
    this.props.onUserChange({});
    location.href = '/tool/login';
  }

  eventHandler(e) {
    if (e === 'reload') {
      this.loadAgain();
    }
  }

  newSetting(event) {
    event.preventDefault();
    if ('api_token' in this.props.currentUser == false) {
      return;
    }
    this.setState({ loading: true });
    let postData = {};
    postData['api_token'] = this.props.currentUser.api_token;
    const payload = JSON.stringify(postData);
    fetch(this.context.API_URL + 'ftpsettings', {
      method: 'POST',
      body: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (!res.ok) {
          this.setState({
            error: 'Please try again (' + res.statusText + ').',
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        this.loadAgain();
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({
          error: 'Network Error (' + error + ').',
        });
      });
  }

  loadPhotographers() {
    if ('api_token' in this.props.currentUser == false) {
      return;
    }
    this.setState({ loading: true, error: '' });
    fetch(
      this.context.API_URL +
        'photographers?api_token=' +
        this.props.currentUser.api_token,
      {
        headers: { Accept: 'application/json' },
      }
    )
      .then((res) => {
        if (!res.ok) {
          this.setState({
            photographers: [],
            loading: false,
            error: 'HTTP error! Status: ' + res.statusText,
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        this.setState({ photographers: data, loading: false, error: '' });
      })
      .catch((error) => {
        this.setState({
          error: 'Network Error (' + error + ').',
        });
        this.setState({
          photographers: [],
          loading: false,
          error: 'HTTP error! ' + error,
        });
      });
  }

  render() {
    // console.log(this.props.currentUser);
    if ('api_token' in this.props.currentUser == false) {
      return <Redirect to="/tool/login" />;
    }
    if (this.state.loading) {
      return (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 150 }}
        >
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    const output =
      this.state.error == '' ? (
        <div className="list-group">
          {this.state.ftpSettings.map((item, index) => (
            <FtpSetting
              key={item.id}
              ftpSetting={item}
              photographers={this.state.photographers}
              parentIndex={index}
              currentUser={this.props.currentUser}
              eventHandler={this.eventHandler}
            />
          ))}
        </div>
      ) : (
        <div>{this.state.error}</div>
      );
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={this.logout}
            className="btn btn-light"
            style={{
              marginLeft: 'auto',
              marginRight: 0,
            }}
          >
            Logout
          </button>
        </div>
        <div>
          <button
            onClick={this.newSetting}
            className="btn btn-primary btn-sm"
            style={{marginBottom: 10}}
          >
            Neuer FTP Eintrag
          </button>
          {output}
        </div>
      </div>
    );
  }
}
Bildverteilung.contextType = ApiContext;
