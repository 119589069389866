import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

export class PhotographersModal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = { allPhotographers: [], toggle: 'auswählen' };
    this.sendChosen = this.sendChosen.bind(this);
    // this.checkedPhotographers=[];
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    $('#photographers').modal({ backdrop: 'static' });
    // preselect the chosen photographers from ftpSetting
    let newPhotographers = [];
    this.props.allPhotographers.forEach((item) => {
      const found = this.props.photographers.find(
        (test) => test.id == item.id
      );
      if (found && (! ('license_rm' in found))) {
        // workaround to migrate old data
        // Photographer was checked, so we check all
        item.license_rm = true;
        item.license_ed = true;
        item.license_lbrf = true;
        item.license_rfp = true;
      }
       else 
      {
        if (found?.license_rm === true) {
          item.license_rm = true;
        } else {
          item.license_rm = false;
        }
        if (found?.license_ed === true) {
          item.license_ed = true;
        } else {
          item.license_ed = false;
        }
        if (found?.license_lbrf === true) {
          item.license_lbrf = true;
        } else {
          item.license_lbrf = false;
        }
        if (found?.license_rfp === true) {
          item.license_rfp = true;
        } else {
          item.license_rfp = false;
        }
      }
      // }
      newPhotographers.push(item);
    });
    this.setState({ allPhotographers: newPhotographers });
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  toggleChosen(index, field) {
    let photographers = this.state.allPhotographers;
    photographers[index][field] = photographers[index][field] ? false : true;
    this.setState({ allPhotographers: photographers });
  }
  sendChosen() {
    const photographers = this.state.allPhotographers.filter(
      (test) =>
        ((test.license_rm && this.props.ftpSetting.license_rm) ||
         (test.license_ed && this.props.ftpSetting.license_ed) ||
         (test.license_lbrf && this.props.ftpSetting.license_lbrf) ||
         (test.license_rfp && this.props.ftpSetting.license_hprf)) == true
    );
    const partnerIds = photographers.map((p) => {
      return {
        id: p.id,
        license_rm: p.license_rm,
        license_ed: p.license_ed,
        license_lbrf: p.license_lbrf,
        license_rfp: p.license_rfp,
      };
    });
    this.props.eventHandler('photographers', { chosen: partnerIds });
  }
  toggleAll() {
    let photographers = this.state.allPhotographers;
    photographers.forEach((p) => {
      p.license_rm = this.state.toggle == 'auswählen';
      p.license_ed = this.state.toggle == 'auswählen';
      p.license_lbrf = this.state.toggle == 'auswählen';
      p.license_rfp = this.state.toggle == 'auswählen';
    });
    this.setState({
      allPhotographers: photographers,
      toggle: this.state.toggle == 'auswählen' ? 'abwählen' : 'auswählen',
    });
  }

  render() {
    return ReactDOM.createPortal(
      <div
        className="modal fade"
        id="photographers"
        tabIndex="-1"
        aria-labelledby="confirm"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Fotografen ausfiltern: {this.props.ftpSetting.agentur}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={this.props.eventHandler}
                aria-label="Close"
                id="dismiss"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12" style={{ paddingBottom: 10 }}>
                  Die Fotografen werden für die ausgewählten Lizenzen nicht bei dieser Agentur 
                  berücksichtigt:
                </div>
              </div>
              <form>
                <div className="row">
                  {this.state.allPhotographers.map((item, index) => (
                    <div className="col-3 text-truncate" key={index}>
                      {item.name} {item.city}
                      <div className="form-inline">
                      {this.props.ftpSetting.license_rm ? (
                        <div className="form-check mb-2 mr-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="license_rm"
                            checked={item.license_rm}
                            onChange={() =>
                              this.toggleChosen(index, 'license_rm')
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="license_rm"
                            style={{ fontSize: '0.8em' }}
                          >
                            RM
                          </label>
                        </div>
                      ) : null }
                      {this.props.ftpSetting.license_ed ? (
                        <div className="form-check mb-2 mr-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="license_ed"
                            checked={item.license_ed}
                            onChange={() =>
                              this.toggleChosen(index, 'license_ed')
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="license_ed"
                            style={{ fontSize: '0.8em' }}
                          >
                            ED
                          </label>
                        </div>
                      ) : null }
                      {this.props.ftpSetting.license_lbrf ? (
                        <div className="form-check mb-2 mr-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="license_lbrf"
                            checked={item.license_lbrf}
                            onChange={() =>
                              this.toggleChosen(index, 'license_lbrf')
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="license_lbrf"
                            style={{ fontSize: '0.8em' }}
                          >
                            LBRF
                          </label>
                        </div>
                      ) : null }
                      {this.props.ftpSetting.license_hprf ? (
                        <div className="form-check mb-2 mr-sm-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="license_rfp"
                            checked={item.license_rfp}
                            onChange={() =>
                              this.toggleChosen(index, 'license_rfp')
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="license_rfp"
                            style={{ fontSize: '0.8em' }}
                          >
                            RFP
                          </label>
                        </div>
                      ) : null }
                      </div>
                      {/* </label> */}
                    </div>
                  ))}
                </div>
              </form>
            </div>
            <div
              className="modal-footer"
              style={{ justifyContent: 'space-between' }}
            >
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  id="toggleAll"
                  onClick={() => {
                    this.toggleAll();
                  }}
                >
                  Alle {this.state.toggle}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: 10 }}
                  id="dismiss"
                  onClick={this.props.eventHandler}
                  data-dismiss="modal"
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="photographers"
                  onClick={this.sendChosen}
                  data-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      this.el
    );
  }
}
