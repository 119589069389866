import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
    $('#confirm').modal({backdrop: 'static'});
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      (<div
        className="modal fade"
        id="confirm"
        tabIndex="-1"
        aria-labelledby="confirm"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                { this.props.mTitle }
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={this.props.eventHandler}
                aria-label="Close"
                id='dismiss'
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { this.props.mText }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                id="dismiss"
                onClick={this.props.eventHandler}
                data-dismiss="modal"
              >
                Abbrechen
              </button>
              <button type="button" className="btn btn-primary"
              id="deleteOk"
              onClick={this.props.eventHandler}
              data-dismiss="modal">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    ), this.el);
  }
}
