import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Login } from './Login';
import { Bildverteilung } from './Bildverteilung';
import {ApiContext} from '../apiContext';

class MainRouter extends React.Component {
  constructor(props) {
    super(props);
    this.API_URL = '/api/';
    this.state = {
      currentUser: [],
    };
    this.userChange = this.userChange.bind(this);
  }

  userChange(data) {
    if ('api_token' in data) {
      this.createCookie('api_token', data['api_token'], 0.3);
    } else {
      this.createCookie('api_token', '', -1);
    }
    this.setState({ currentUser: data });
  }

  render() {
    return (
      <ApiContext.Provider value={{API_URL: '/api/'}}>
      <Router>
        <div>
          <Switch>
            <Route path="/tool/bildverteilung">
              <Bildverteilung
                currentUser={this.state.currentUser}
                onUserChange={this.userChange}
              />
            </Route>
            <Route path="/">
              <Login
                currentUser={this.state.currentUser}
                onUserChange={this.userChange}
              />
            </Route>
          </Switch>
        </div>
      </Router>
      </ApiContext.Provider>
    );
  }

  createCookie(cookieName, cookieValue, daysToExpire) {
    let date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    document.cookie =
      cookieName +
      '=' +
      cookieValue +
      ';SameSite=Strict;path=/;expires=' +
      date.toUTCString();
  }
}

if (document.getElementById('root')) {
  ReactDOM.render(<MainRouter />, document.getElementById('root'));
}
