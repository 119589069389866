import React from 'react';
import { ApiContext } from '../apiContext';
import { Modal } from './Modal';
import { PhotographersModal } from './PhotographersModal';

export class FtpSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ftpSetting: props.ftpSetting,
      error: '',
      saved: true,
      loading: false,
      showModal: false,
      showPhotographers: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.askDelete = this.askDelete.bind(this);
    this.modalEvent = this.modalEvent.bind(this);
    this.showPhotographers = this.showPhotographers.bind(this);
    this.settingsForm = React.createRef();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let tempSetting = this.state.ftpSetting;
    tempSetting[name] = value;
    this.setState({
      ftpSetting: tempSetting,
      saved: false
    });
  }

  // componentDidMount() {
  //   this.loadAgain();
  // }
  // componentWillUnmount() {
  // }

  render() {
    const ftpId = this.state.ftpSetting.id;
    return (
      <div
        className={
          this.props.parentIndex % 2 == 0
            ? 'list-group-item'
            : 'list-group-item ftpSettingBg'
        }
      >
        <form
          ref={(ref) => {
            this.form = ref;
          }}
          onSubmit={this.save}
        >
          <div className="row">
            <div className="col-3 pr-1 form-inline flextop">
              <label className="labelFtpSetting" htmlFor={'agentur_' + ftpId}>
                Agentur
              </label>
              <input
                type="text"
                className="form-control form-control-sm formTextSmaller mb-2 mr-sm-2"
                id={'agentur_' + ftpId}
                name="agentur"
                required
                value={this.state.ftpSetting.agentur}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-3 pl-1 pr-1 flextop">
              <div className='form-inline'>
                <div className="form-check mb-2 mr-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="license_rm"
                    checked={this.state.ftpSetting.license_rm}
                    onChange={this.handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="license_rm" style={{ fontSize: '0.8em'}}>
                    RM
                  </label>
                </div>
                <div className="form-check mb-2 mr-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="license_ed"
                    checked={this.state.ftpSetting.license_ed}
                    onChange={this.handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="license_ed" style={{ fontSize: '0.8em'}}>
                    ED
                  </label>
                </div>
                <div className="form-check mb-2 mr-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="license_lbrf"
                    checked={this.state.ftpSetting.license_lbrf}
                    onChange={this.handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="license_lbrf" style={{ fontSize: '0.8em'}}>
                    LBRF
                  </label>
                </div>
                <div className="form-check mb-2 mr-sm-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="license_hprf"
                    checked={this.state.ftpSetting.license_hprf}
                    onChange={this.handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="license_hprf" style={{ fontSize: '0.8em'}}>
                    RFP
                  </label>
                </div>
              </div>
              <div className="form-inline">
                <label htmlFor='aiSelect'>AI generiert?</label>
                <select
                  id="aiSelect"
                  style={{ marginLeft: 5 }}
                  className="form-control form-control-sm"
                  name="ai_generated"
                  value={this.state.ftpSetting.ai_generated}
                  onChange={this.handleInputChange}
                >
                  <option value="0">Alle Bilder</option>
                  <option value="1">Nur AI gen.</option>
                  <option value="2">Keine AI gen.</option>
                </select>
              </div>
            </div>

            <div className="col-3 pr-1 form-inline flextop">
              <select
                style={{ marginRight: 10 }}
                className="form-control form-control-sm"
                name="keywords"
                value={this.state.ftpSetting.keywords}
                onChange={this.handleInputChange}
              >
                <option>Default</option>
                <option>Deutsch</option>
                <option>English</option>
              </select>
              <select
                className="form-control form-control-sm"
                name="resolution"
                value={this.state.ftpSetting.resolution}
                onChange={this.handleInputChange}
              >
                <option>HiRes</option>
                <option>Thumbs</option>
              </select>
            </div>
            {this.state.loading === true ? (
              <div className="col-3 text-right">
                <div
                  className="spinner-border text-info"
                  role="status"
                  style={{ marginRight: 20 }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-3 text-right">
                <svg
                  className={ this.state.saved ? 'bi text-primary' : 'bi text-danger' }
                  width="35"
                  height="35"
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    this.form.dispatchEvent(new Event('submit', { cancelable: true }));
                  }}
                >
                  <use xlinkHref="/assets/bootstrap-icons.svg#check" />
                </svg>
                <svg
                  className="bi text-danger"
                  width="25"
                  height="25"
                  onClick={this.askDelete}
                >
                  <use xlinkHref="/assets/bootstrap-icons.svg#trash" />
                </svg>
              </div>
            )}
          </div>

          <div className="row pt-2">
            <div className="col-3 form-inline">
              <label className="labelFtpSetting" htmlFor="ftp_host">
                FTP Host
              </label>
              <input
                type="text"
                className="form-control form-control-sm formTextSmaller mb-2 mr-sm-2"
                name="ftp_host"
                required
                value={this.state.ftpSetting.ftp_host}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-3 form-inline">
              <label className="labelFtpSetting" htmlFor="ftp_user">
                FTP User
              </label>
              <input
                type="text"
                className="form-control form-control-sm formTextSmaller mb-2 mr-sm-2"
                name="ftp_user"
                required
                value={this.state.ftpSetting.ftp_user}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-3 form-inline">
              <label className="labelFtpSetting" htmlFor="ftp_pass">
                Passwort
              </label>
              <input
                type="text"
                className="form-control form-control-sm formTextSmaller mb-2 mr-sm-2"
                name="ftp_pass"
                required
                value={this.state.ftpSetting.ftp_pass}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-2 form-inline">
              <div className="form-check mb-2 mr-sm-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="active"
                  checked={this.state.ftpSetting.active}
                  onChange={this.handleInputChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Aktiv
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-9 form-inline">
              <label className="labelFtpSetting" htmlFor="ftp_path">
                FTP Pfad
              </label>
              <input
                type="text"
                className="form-control form-control-sm formTextBigger mb-2 mr-sm-2"
                name="ftp_path"
                required
                value={this.state.ftpSetting.ftp_path}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-3">
              &nbsp;
            </div>
          </div>
          <div className="row">
            <div className="col-9 form-inline">
              <label className="labelFtpSetting" htmlFor="ftp_stopwords">
                Stopwords
              </label>
              <textarea
                className="form-control form-control-sm mb-2 mr-sm-2"
                cols="72"
                rows="2"
                maxlength="1000"
                name="stopwords"
                onChange={this.handleInputChange}
              >{this.state.ftpSetting.stopwords}</textarea>
            </div>
            <div className="col-3">
              <button type="button" className="btn btn-outline-secondary" onClick={this.showPhotographers}>
                <svg
                  className="bi text-primary"
                  width="25"
                  height="25"
                  style={{marginRight: 5}}                  
                >
                  <use xlinkHref="/assets/bootstrap-icons.svg#people-fill" />
                </svg>
                Ausfiltern ({ (JSON.parse(this.state.ftpSetting.photographers === null ? '[]' : this.state.ftpSetting.photographers)).length })
              </button>
            </div>
          </div>
          {this.state.error != '' ? <p>{this.state.error}</p> : ''}
        </form>
        {this.state.showModal ? (
          <Modal
            eventHandler={this.modalEvent}
            mTitle="Bestätigung"
            mText="Wirklich löschen?"
          />
        ) : null}
        {this.state.showPhotographers ? (
          <PhotographersModal
            eventHandler={this.modalEvent}
            ftpSetting={this.state.ftpSetting}
            allPhotographers={this.props.photographers}
            photographers={JSON.parse(this.state.ftpSetting.photographers === null ? '[]' : this.state.ftpSetting.photographers )}
          />
        ) : null}
      </div>
    );
  }

  save(event) {
    event.preventDefault();
    if ('api_token' in this.props.currentUser == false) {
      return;
    }
    const validForm = event.target.checkValidity();
    event.target.reportValidity();
    if (validForm === false) {
      return;
    }
    this.setState({ loading: true });
    let postData = this.state.ftpSetting;
    postData['api_token'] = this.props.currentUser.api_token;
    const payload = JSON.stringify(postData);
    fetch(this.context.API_URL + 'ftpsettings/' + this.state.ftpSetting.id, {
      method: 'PUT',
      body: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        setTimeout(() => this.setState({ loading: false }), 100);
        if (!res.ok) {
          this.setState({
            error: 'Please try again (' + res.statusText + ').',
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        this.setState({saved: true});
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({
          error: 'Network Error (' + error + ').'
        });
        console.log(error);
      });
  }

  modalEvent(e, params={}) {
    const eventId = (typeof(e) == 'object' ? e.currentTarget['id'] : e);
    if (eventId == 'dismiss') {
      this.setState({ showModal: false });
      this.setState({ showPhotographers: false });
      return;
    }
    if (eventId == 'deleteOk') {
      this.setState({ showModal: false });
      this.reallyDelete();
    }
    if (eventId == 'photographers' && ('chosen' in params)) {
      this.setState({ showPhotographers: false });
      let setting=this.state.ftpSetting;
      setting.photographers=JSON.stringify(params.chosen);
      this.setState({ftpSetting: setting, saved: false});
    }
  }

  askDelete(event) {
    this.setState({ showModal: true });
  }
  reallyDelete() {
    if ('api_token' in this.props.currentUser == false) {
      return;
    }
    this.setState({ loading: true });
    let postData = {};
    postData['api_token'] = this.props.currentUser.api_token;
    const payload = JSON.stringify(postData);
    fetch(this.context.API_URL + 'ftpsettings/' + this.state.ftpSetting.id, {
      method: 'DELETE',
      body: payload,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          this.setState({
            error: 'Please try again (' + res.statusText + ').',
          });
          this.setState({ loading: false });
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          console.log(data);
          return;
        }
        setTimeout(() => {
          this.props.eventHandler('reload');
        }, 500);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({
          error: 'Network Error (' + error + ').',
        });
      });
  }

  showPhotographers() {
    this.setState({ showPhotographers: true });
  }
}
FtpSetting.contextType = ApiContext;
